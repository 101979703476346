import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Pages/Home';
import { ThemeProvider } from './ThemeContext';
import NodeSale from './Pages/NodeSale';
import { useEffect } from 'react';
import EcoSystem from './Pages/EcoSystem';
import NodeSaleV2 from './Pages/NodeSaleV2';


function App() {
  useEffect(() => {
    if (hotjar.initialized()) {
      return;
    }

    hotjar.initialize({ id: 4995593, sv: 6 });
  }, [])
  return (
    <ThemeProvider>
      <Router> 
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/nodesale" element={<NodeSale />} /> */}
          <Route path="/nodesale" element={<NodeSaleV2 />} />
          <Route path="/ecosystem" element={<EcoSystem />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
