import React, { useState, useEffect } from "react";
import data from "../Data/data.json";

interface Item {
  name: string;
  image: string;
  link: string;
}

interface Category {
  name: string;
  items: Item[];
}

const ITEMS_PER_PAGE = 12;

const AllApps: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("All");

  useEffect(() => {
    setCategories(data.categories);
    setFilteredItems(data.categories.flatMap((category) => category.items));
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    let filtered = categories
      .flatMap((category) => category.items)
      .filter((item) => item.name.toLowerCase().includes(value));

    // If no match is found, show the first 5 items as a fallback
    if (filtered.length === 0) {
      filtered = categories.flatMap((category) => category.items).slice(0, 5);
    }

    setFilteredItems(filtered);
    setCurrentPage(1);
  };

  const handleCategoryFilter = (categoryName: string) => {
    setSelectedCategory(categoryName); // Update the selected category

    if (categoryName === "All") {
      setFilteredItems(categories.flatMap((category) => category.items));
    } else {
      const filtered = categories
        .filter((category) => category.name === categoryName)
        .flatMap((category) => category.items);

      setFilteredItems(filtered);
    }
    setCurrentPage(1);
  };

  const handlePageChange = (direction: "prev" | "next") => {
    setCurrentPage((prevPage) => {
      if (direction === "prev") return prevPage > 1 ? prevPage - 1 : prevPage;
      if (direction === "next")
        return prevPage < Math.ceil(filteredItems.length / ITEMS_PER_PAGE)
          ? prevPage + 1
          : prevPage;
      return prevPage;
    });
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const displayedItems = filteredItems.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const isPrevDisabled = currentPage === 1;
  const isNextDisabled =
    currentPage === Math.ceil(filteredItems.length / ITEMS_PER_PAGE);

  return (
    <>
      <div className="row">
        <div className="col-md-8 order-md-0 order-1">
          <h2 className="mb-4">Explore the ecosystem</h2>
          <div className="input-with-ic">
            <input
              type="text"
              placeholder=""
              value={searchTerm}
              onChange={handleSearch}
            />
            <a href="javascript:void(0)">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2965_807)">
                <path d="M22.2644 20.9494L16.7903 15.2561C18.1978 13.5829 18.969 11.4778 18.969 9.28624C18.969 4.16589 14.8031 0 9.68272 0C4.56237 0 0.396484 4.16589 0.396484 9.28624C0.396484 14.4066 4.56237 18.5725 9.68272 18.5725C11.605 18.5725 13.4368 17.9927 15.0029 16.8921L20.5186 22.6286C20.7491 22.868 21.0592 23 21.3915 23C21.706 23 22.0044 22.8801 22.2309 22.6621C22.7121 22.199 22.7275 21.431 22.2644 20.9494ZM9.68272 2.4225C13.4675 2.4225 16.5465 5.50149 16.5465 9.28624C16.5465 13.071 13.4675 16.15 9.68272 16.15C5.89798 16.15 2.81898 13.071 2.81898 9.28624C2.81898 5.50149 5.89798 2.4225 9.68272 2.4225Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_2965_807">
                <rect width="23" height="23" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            </a>
          </div>
          <div className="apps-bg">
                <div className="allApps">
            <div className="row">
              {displayedItems.map((item, index) => (
                <div className="col-md-3 col-4 mb-md-4 mb-2" key={index}>
                  <a href={item.link} target={"_blank"} className="app-card">
                    <img
                      src={item.image}
                      alt=""
                      style={{ maxWidth: "60px" }}
                    />
                    <p className="text-truncate">{item.name}</p>
                  </a>
                </div>
              ))}
                </div>
            </div>

            {filteredItems.length > ITEMS_PER_PAGE && (
              <div className="d-flex gap-4 justify-content-center justify-content-md-end">
                <a
                  href="javascript:void(0)"
                  onClick={() => handlePageChange("prev")}
                  className={isPrevDisabled ? "disabled" : ""}
                >
                  <svg style={{transform: "rotate(180deg)"}}
                    width="53"
                    height="13"
                    viewBox="0 0 53 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M53 6.5L43 0.726497V12.2735L53 6.5ZM0 7.5H44V5.5H0V7.5Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="javascript:void(0)"
                  onClick={() => handlePageChange("next")}
                  className={isNextDisabled ? "disabled" : ""}
                >
                  <svg
                    width="53"
                    height="13"
                    viewBox="0 0 53 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M53 6.5L43 0.726497V12.2735L53 6.5ZM0 7.5H44V5.5H0V7.5Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-4 mb-md-0 mb-4">
          <div className="categories">
            <h4 className="mb-4">Ecosystem</h4>
            <button
              onClick={() => handleCategoryFilter("All")}
              className={selectedCategory === "All" ? "active" : ""}
            >
              All
            </button>
            {categories.map((category, index) => (
              <button
                key={index}
                onClick={() => handleCategoryFilter(category.name)}
                className={
                  selectedCategory === category.name ? "active" : ""
                }
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllApps;
