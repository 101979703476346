import React, { useState, useEffect } from "react";
import partners from "../../Data/partners.json"; // Adjust the path as necessary

interface Item {
  image: string;
  col: string;
}

interface Category {
  name: string;
  items: Item[];
}

interface CategoriesData {
  categories: Category[];
}

const Categories: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    setCategories(partners.categories);
  }, []);

  // Define specific categories for the two different loops
  const firstLoopCategories = categories.filter(category =>
    ["DeFi", "Data availability", "Wallets", "Bridge", "Social" ].includes(category.name)
  );

  const secondLoopCategories = categories.filter(category =>
    ["NFT, Gaming & AI" , "Security", "Infra & Tooling", "Clients"].includes(category.name)
  );

  return (
    <>
      <div className="cat-bg">
      <div className="row">
        {/* First Loop */}
        <div className="col-md-6" >
        {firstLoopCategories.map((category, index) => (
            <div className="cat-card mb-5" key={index}>
              <div className="bg-cat">
                <div className="cat-name">{category.name}</div>
                <div className="row justify-content-center align-items-center">
                  {category.items.map((item, itemIndex) => (
                    <div className={`${item.col}`} style={{mixBlendMode: "lighten"}} key={itemIndex}>
                      <img src={item.image} className="w-100" alt={category.name} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
        ))}
        </div>

        {/* Second Loop */}
        <div className="col-md-6" >
        {secondLoopCategories.map((category, index) => (
            <div className="cat-card mb-5" key={index}>
              <div className="bg-cat">
                <div className="cat-name">{category.name}</div>
                <div className="row justify-content-center align-items-center">
                  {category.items.map((item, itemIndex) => (
                    <div className={`mb-4 ${item.col}`} style={{mixBlendMode: "lighten"}} key={itemIndex}>
                      <img src={item.image} className="w-100" alt={category.name} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
        ))}
        </div>
      </div>
      </div>
    </>
  );
};

export default Categories;
