import React from 'react';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container pt-3 pb-lg-2 ">
          <div className="f-card">
          <div className="row">
          <div className="col-lg-2 col-md-4 col-6 mb-lg-0 mb-4">
              <h5>Developers</h5>
              <ul>
                  <li><a href="https://docs.stratovm.io/" target={"_blank"}>Documentation</a></li>
                  <li><a href="https://app.solidproof.io/projects/strato-vm?audit_id=1127" target={"_blank"}>Audit</a></li>
                  <li><a href="https://medium.com/@StratoVM_/stratovm-accelerator-program-fueling-innovation-in-defi-on-bitcoin-8f42abc9df15"
                   target={'_blank'} >StratoVM Accelerator </a></li>
                  <li><a href="https://github.com/StratoVM" target={"_blank"}>Github</a></li>
                  <li><a href="https://discord.gg/StratoVM" target={'_blank'}>Developer Chat Support</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-lg-0 mb-4">
              <h5>Individuals</h5>
              <ul>
                  <li><a href="#ecoSystem">Explore Ecosystem</a></li>
                  <li><a href="https://explorer.stratovm.io/" target={'_blank'}>StratoVM Explorer</a></li>
                  <li><a href="https://discord.gg/StratoVM" target={'_blank'}>Submit Feedback</a></li>
                  <li><a href="https://bridge.stratovm.io/" target={'_blank'}>StratoVM Bridge</a></li>
              </ul>
            </div>
              <div className="col-lg-2 col-md-4 col-6 mb-lg-0 mb-4">
              <h5>Institutions</h5>
              <ul>
                <li><a href="javascript:void(0)">Institutions Overview <span>Soon</span></a></li>
                <li><a href="javascript:void(0)" className='d-inline'>Join The Institutional Programs <span>Soon</span></a></li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-4 col-6 mb-lg-0 mb-4">
              <h5>About</h5>
              <ul>
                <li><a href="https://medium.com/@StratoVM_/stratovm-unlocking-defis-potential-on-bitcoin-s-bedrock-c23781131fc1"
                target={'_blank'}>About StratoVM</a></li>
                {/* <li><a href="https://absorbing-talos-60d.notion.site/StratoVM-Job-Board-708e8b98822347ecb7a71b3ff8439392?pvs=4" target={"_blank"}>Careers</a></li> */}
                <li><a href="https://medium.com/@StratoVM_" target={"_blank"}>Blog</a></li>
                <li><a href="https://docs.stratovm.io/protocol/brand-asssets" target={'_blank'}>Press Kit </a></li>
                <li><a href="https://discord.gg/StratoVM" target={'_blank'}>Media Contact</a></li>

              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-lg-0">
              <h5>Community</h5>
              <ul>
                <li><a href="https://twitter.com/StratoVM_" target={"_blank"}>Twitter</a></li>
                <li><a href="https://discord.gg/StratoVM" target={"_blank"}>Discord</a></li>
                <li><a href="https://app.galxe.com/quest/X8QrV4cqcjYssXZ2EpS6va/GCdtztd8zf" target={'_blank'}>Galxe</a></li>
                <li><a href="https://medium.com/@StratoVM_" target={"_blank"}>Medium</a></li>
                <li><a href="https://t.me/StratoVMAnnouncements" target={"_blank"}>Telegram</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-lg-0">
              <h5>Legal</h5>
              <ul>
                <li><a href="https://docs.stratovm.io/protocol/terms-of-use" target={'_blank'}>Terms of Service</a></li>
                <li><a href="https://docs.stratovm.io/protocol/privacy-policy" target={'_blank'}>Privacy Policy</a></li>
                <li><a href="https://docs.stratovm.io/protocol/disclaimer" target={'_blank'}>Disclaimer</a></li>
              </ul>
            </div>
              </div>
              <div className="text-center mt-4 mt-md-5 footer-logo">
              <a href="#"><img src="assets/logo.svg" className='op-dark' alt="" />
              <img src="assets/logo-vm.svg" className='op-light' alt="" /></a>
              </div>
          </div>
        </div>
        <hr />
        <div className="container mb-3">
        <div className="sub-footer">Copyright© 2024 StratoVM. All rights reserved.</div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
