import React, { useEffect } from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link, useLocation } from 'react-router-dom';
import AllApps from './AllApps';
import NewsLetter from './NewsLetter';

export default function EcoSystem() {

    useEffect(function () {
        Aos.init({ disable: 'mobile' });
      }, []);


  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    }, [location]);

  return (
    <>
      <div className="nodesale">
        {/* Banner */}
      <div className="bg-grad">
        <section className="banner" id="home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mb-md-0 mb-4" data-aos="fade-up" data-aos-duration="1800">
                <h1 className="mb-0">
                StratoVM
                <br /> <span className="danger-text">Ecosystem</span>
                </h1>
                <div className="col-md-6" data-aos="zoom-in" data-aos-duration="1800">
              <img src="assets/ecoSystem.png" className='w-100 d-md-none d-block mt-4' alt="" />              
              </div>
                <p className="my-4 pe-lg-5">
                StratoVM has built the largest and fastest growing Web3 financial ecosystem. 
                Explore the global network of dApps, validators, builders and partners working 
                together to create a truly free and inclusive financial world.
                </p>
                
              </div>

              <div className="col-md-6" data-aos="zoom-in" data-aos-duration="1800">
              <img src="assets/ecoSystem.png" className='w-100 d-md-block d-none' alt="" />              
              </div>
              
            </div>
          </div>
        </section>
        </div>
      </div>

      <div className="ecosystem">
        <section className='blackBg py-5'>
            <div className="container py-lg-4">
                <div className="row">
                    <div className="col-md-12 mb-md-4 mb-3">
                        <h2>Featured dApps</h2>
                    </div>
                    <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                        <a href='https://testnet.bitlen.io/' target={"_blank"} className="feature-card">
                            <div>
                            <div className="top-content pb-3">
                            <div className="d-flex align-items-start justify-content-between">
                                <img src="assets/apps/bit.png" alt="" />
                                <div className="feature-badge">Stable Coin</div>
                            </div>
                            <h5 className='pt-3 mb-0'>Bitlen</h5>
                            </div>
                            <div className="bottom-content">
                                <p className='mb-0'>BitLen Finance is a liquidity-boosting layer on 
                                    Bitcoin with various products, including their 
                                    latest, HEX—a BTC-backed stablecoin designed 
                                    to enhance stability, accessibility, and yield 
                                    generation.</p>
                            </div>
                            </div>
                            <div className="feature-link">
                              <a href="https://testnet.bitlen.io/" target={"_blank"} className='primary-btn'>Live on Testnet</a>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                        <a href='https://bitomni.io/en' target={"_blank"} className="feature-card">
                            <div>
                            <div className="top-content pb-3">
                            <div className="d-flex align-items-start justify-content-between">
                                <img src="assets/apps/bito.png" alt="" />
                                <div className="feature-badge">Asset Management</div>
                            </div>
                            <h5 className='pt-3 mb-0'>Bitomni</h5>
                            </div>
                            <div className="bottom-content">
                                <p className='mb-0'>Bitomni is a BTCFi-driven omnichain asset management 
                                    protocol that leverages the power of BTC and its tokens by leveraging the ICP technology.</p>
                            </div>
                            </div>
                            <div className="feature-link">
                              <a href="https://bitomni.io/en" target={"_blank"} className='primary-btn'>Live on Testnet</a>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                        <a href='https://icecreamswap.com/?chain=stratovmTestnet' target={"_blank"} className="feature-card">
                            <div>
                            <div className="top-content pb-3">
                            <div className="d-flex align-items-start justify-content-between">
                                <img src="assets/apps/ice.png" alt="" />
                                <div className="feature-badge">Decentralized Exchange</div>
                            </div>
                            <h5 className='pt-3 mb-0'>IceCreamSwap</h5>
                            </div>
                            <div className="bottom-content">
                                <p className='mb-0'>IceCreamSwap is a multichain DeFi ecosystem with Swap, 
                                    Liquidity, Farm, Staking, Bridge, and Launchpad all in one place.</p>
                            </div>
                            </div>
                            <div className="feature-link">
                              <a href="https://icecreamswap.com/?chain=stratovmTestnet" target={"_blank"} className='primary-btn'>Live on Testnet</a>
                            </div>
                        </a>
                    </div>

                    <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                        <a href='https://testnet.rivera.money' target={"_blank"} className="feature-card">
                            <div>
                            <div className="top-content pb-3">
                            <div className="d-flex align-items-start justify-content-between">
                                <img src="assets/apps/revira.png" alt="" />
                                <div className="feature-badge">CLAMM DEX</div>
                            </div>
                            <h5 className='pt-3 mb-0'>Rivera Money</h5>
                            </div>
                            <div className="bottom-content">
                                <p className='mb-0'>Rivera Money is a permissionless liquidity infrastructure for concentrated DEXs. 
                                  Yield tokens are minted by Rivera ALM Vault which ensures users’ assets are concentrated in 
                                  the ‘active’ range and generates optimal yield.</p>
                            </div>
                            </div>
                            <div className="feature-link">
                              <a href="https://testnet.rivera.money" target={"_blank"} className='primary-btn'>Live on Testnet</a>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section className='py-5 mt-lg-5'>
            <div className="container py-lg-4">
                <AllApps />
                
            </div>
        </section>

            <section>
                <div className="container pt-lg-5 mt-lg-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>How to get started <br className='d-md-block d-none' /> with StratoVM</h2>
                        </div>
                        <div className="col-md-12">
                        <ul className="nav nav-tabs justify-content-center justify-content-md-start" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" 
                      type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">For individuals

                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" 
                      type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">For developers</button>
                    </li>
                   
                    
                  </ul>
                  
                  <div className="tab-content pt-4" id="myTabContent">
                    <div className="tab-pane show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab">
                      <div className="row">
                        <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                            <div className="steps-card">
                                <div className="step-img">
                                    <img src="assets/ecosystem/i1.png" className='w-100' alt="" />
                                </div>
                                <div className="step-badge">Step 1</div>
                                <h6>Add StratoVM Sepolia to your wallet</h6>
                            </div>
                        </div>
                        <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                            <div className="steps-card">
                                <div className="step-img">
                                    <img src="assets/ecosystem/i2.png" className='w-100' alt="" />
                                </div>
                                <div className="step-badge">Step 2</div>
                                <h6>Join our <a href='https://discord.com/invite/StratoVM' target={"_blank"}>Official Discord</a> community</h6>
                            </div>
                        </div>
                        <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                            <div className="steps-card">
                                <div className="step-img">
                                    <img src="assets/ecosystem/i3.png" className='w-100' alt="" />
                                </div>
                                <div className="step-badge">Step 3</div>
                                <h6>Claim your <a href='https://discord.com/channels/1235518160921165856/1262676853978562670' target={"_blank"}>$SVM Token</a> from our <a href='https://discord.com/channels/1235518160921165856/1262676853978562670' target={"_blank"}>Faucet Bot</a> on Discord</h6>
                            </div>
                        </div>
                        <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                            <div className="steps-card">
                                <div className="step-img">
                                    <img src="assets/ecosystem/i4.png" style={{maxWidth:"100px"}} className='w-100' alt="" />
                                </div>
                                <div className="step-badge">Step 4</div>
                                <h6>Mint your <a href='https://nftclaim.stratovm.io/' target={"_blank"}>StratoVM Testnet NFT</a></h6>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="row">
                        <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                            <div className="steps-card">
                                <div className="step-img">
                                    <img src="assets/ecosystem/d1.png" className='w-100' alt="" />
                                </div>
                                <div className="step-badge">Step 1</div>
                                <h6>Read the <a href="https://docs.stratovm.io/" target={"_blank"}>Developer Docs</a></h6>
                            </div>
                        </div>
                        <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                            <div className="steps-card">
                                <div className="step-img">
                                    <img src="assets/ecosystem/d2.png" className='w-100' alt="" />
                                </div>
                                <div className="step-badge">Step 2</div>
                                <h6>Collaborate on the <a href="https://github.com/StratoVM" target={"_blank"}>StratoVM GitHub</a></h6>
                            </div>
                        </div>
                        <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                            <div className="steps-card">
                                <div className="step-img">
                                    <img src="assets/ecosystem/d3.png" className='w-100' alt="" />
                                </div>
                                <div className="step-badge">Step 3</div>
                                <h6> Join the <a href="https://discord.com/invite/StratoVM" target={"_blank"}>Developer Chat</a></h6>
                            </div>
                        </div>
                        <div className="col-md-3 col-11 mx-auto mb-md-0 mb-4">
                            <div className="steps-card">
                                <div className="step-img">
                                    <img src="assets/ecosystem/d4.png" className='w-100' alt="" />
                                </div>
                                <div className="step-badge">Step 4</div>
                                <h6>Explore the <Link to={"/#ecoSystem"} >StratoVM Ecosystem</Link></h6>
                            </div>
                        </div>
                      </div>
                    </div>

                  </div>
                        </div>
                    </div>
                </div>
            </section>



        <section className='my-5 py-lg-5'>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-5 mb-md-0 mb-4">
                <a href="https://medium.com/@StratoVM" target={"_blank"} className='news-btn'><div>Blog</div>
                <svg width="53" height="13" viewBox="0 0 53 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M53 6.5L43 0.726497V12.2735L53 6.5ZM0 7.5H44V5.5H0V7.5Z" fill="white"/>
                </svg></a>
              </div>
              <div className="col-md-5">
                <Link to={"/#news"} className='news-btn'><div>Press</div>
                <svg width="53" height="13" viewBox="0 0 53 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M53 6.5L43 0.726497V12.2735L53 6.5ZM0 7.5H44V5.5H0V7.5Z" fill="white"/>
                </svg></Link>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5 mb-md-0 mb-4">
                <div className="fw-500 fs-18 mb-2">Sign up for exclusive news</div>
                <NewsLetter />
              </div>

              <div className="col-md-5">
                <div className="d-flex gap-2 justify-content-md-end justify-content-center">
                
                    <a href="https://discord.gg/StratoVM" target={"_blank"} className="social-ic">
                    <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.16892 15.212C12.2582 17.5624 17.4402 18.0203 23.3125 14.3617C23.2695 14.4256 22.1079 16.1277 19.0532 17C19.6985 17.8724 20.5806 18.8723 20.5806 18.8723C22.5381 18.8723 24.4527 18.3191 26.109 17.2553C27.3997 16.4042 28.1311 14.9362 27.9805 13.4043C27.7224 10.7872 27.0985 8.23405 26.1305 5.78729C24.9044 2.59582 21.9573 0.383032 18.5369 0.0638598C18.2358 0.0425732 18.0207 0.0425734 17.8916 0.0425734L17.5475 0.382969C21.4841 1.48937 23.4417 3.21276 23.4846 3.27662C17.4399 0.255312 10.298 0.212802 4.21019 3.14896C4.21019 3.14896 6.14625 1.25534 10.427 0.255313L10.1689 0C9.71713 0 9.28689 0.042573 8.83519 0.10637C5.67296 0.638281 3.02707 2.78721 1.88693 5.74466C0.897379 8.29785 0.252046 10.9787 0.0154353 13.7021C-0.113631 15.1489 0.574745 16.5745 1.77939 17.4043C3.37123 18.4468 5.26424 19 7.17878 19C7.17878 19 7.95318 18 8.72764 17.1064C5.82355 16.2553 4.64043 14.5532 4.61891 14.4893L5.16545 14.7654C5.49217 14.9305 5.82713 15.08 6.16892 15.212ZM9.54508 13.3617C8.14681 13.3191 7.04972 12.1489 7.09276 10.7447C7.1358 9.42553 8.21138 8.36171 9.54508 8.31913C10.9434 8.36171 12.0405 9.5319 11.9974 10.9362C11.9328 12.2554 10.8788 13.3192 9.54508 13.3617ZM18.3218 13.3617C16.9235 13.3191 15.8264 12.1489 15.8695 10.7447C15.9125 9.42553 16.9881 8.36171 18.3218 8.31913C19.7201 8.36171 20.8172 9.5319 20.7741 10.9362C20.7311 12.2554 19.6555 13.3192 18.3218 13.3617Z" fill="white"/>
                    </svg></a>
                    <a href="https://twitter.com/StratoVM_" target={"_blank"} className="social-ic">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_740_1704)">
                    <path d="M13.0458 9.3155L21.0597 0H19.1607L12.2022 8.08852L6.64453 0H0.234375L8.63871 12.2313L0.234375 22H2.13352L9.48183 13.4583L15.3512 22H21.7613L13.0454 9.3155H13.0458ZM10.4447 12.339L9.59316 11.1211L2.81781 1.42964H5.73478L11.2026 9.25094L12.0541 10.4689L19.1616 20.6354H16.2446L10.4447 12.3395V12.339Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_740_1704">
                    <rect width="22" height="22" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg> </a>
                    <a href="https://medium.com/@StratoVM_" target={"_blank"} className="social-ic">
                    <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.7935 7.99979C15.7935 12.391 12.258 15.9508 7.89677 15.9508C3.53549 15.9508 0 12.391 0 7.99979C0 3.6086 3.53549 0.0488281 7.89677 0.0488281C12.258 0.0488281 15.7935 3.6086 15.7935 7.99979Z" fill="white"/>
                    <path d="M24.4593 8.00005C24.4593 12.1336 22.6915 15.4845 20.5109 15.4845C18.3303 15.4845 16.5625 12.1336 16.5625 8.00005C16.5625 3.86649 18.3303 0.515625 20.5109 0.515625C22.6915 0.515625 24.4593 3.86649 24.4593 8.00005Z" fill="white"/>
                    <path d="M28.0039 7.99967C28.0039 11.7032 27.3821 14.7054 26.6152 14.7054C25.8482 14.7054 25.2266 11.7032 25.2266 7.99967C25.2266 4.29618 25.8482 1.29395 26.6152 1.29395C27.3821 1.29395 28.0039 4.29618 28.0039 7.99967Z" fill="white"/>
                    </svg></a>
                    <a href="https://github.com/StratoVM" target={"_blank"} className="social-ic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.6rem" height="1.6rem" viewBox="0 0 24 24"><path fill="white" d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33s1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2"/></svg>
                    </a>
                    
                    <a href="https://t.me/StratoVMAnnouncements" target={"_blank"} className="social-ic">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1245_98)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM17.562 8.161C17.382 10.058 16.6 14.663 16.203 16.788C16.035 17.688 15.703 17.989 15.383 18.018C14.686 18.082 14.157 17.557 13.482 17.115C12.426 16.423 11.829 15.992 10.804 15.316C9.619 14.535 10.387 14.106 11.062 13.405C11.239 13.221 14.309 10.428 14.369 10.175C14.376 10.143 14.384 10.025 14.313 9.963C14.242 9.901 14.139 9.922 14.065 9.939C13.959 9.963 12.272 11.078 9.003 13.284C8.524 13.613 8.09 13.773 7.701 13.765C7.273 13.756 6.449 13.523 5.836 13.323C5.085 13.079 4.487 12.949 4.539 12.535C4.566 12.319 4.863 12.098 5.431 11.872C8.929 10.348 11.262 9.343 12.429 8.857C15.762 7.471 16.454 7.23 16.906 7.222C17.472 7.214 17.608 7.681 17.562 8.161Z" fill="white"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1245_98">
                      <rect width="24" height="24" fill="white"/>
                      </clipPath>
                      </defs>
                      </svg>
                      </a>
                      <a href="https://app.galxe.com/quest/X8QrV4cqcjYssXZ2EpS6va" target={"_blank"} className="social-ic">
                      <img src="assets/galex.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>


       

    </>
  )
}
