import React, { useState } from 'react';
import axios from 'axios';

const NewsLetter: React.FC = () => {
    const [email, setEmail] = useState('');
    const [tosAccepted, setTosAccepted] = useState(false);
    const [errors, setErrors] = useState<{ email?: string, tosAccepted?: string }>({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const validate = () => {
        const newErrors: { email?: string, tosAccepted?: string } = {};
        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!tosAccepted) {
            newErrors.tosAccepted = 'You must accept the privacy policy';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validate()) {
            return;
        }

        try {
            const response = await axios.post('https://api.stratovm.io/api/subscribers', { email });
            if (response.status === 200) {
                setSuccessMessage('Successfully subscribed!');
                setErrorMessage('');
                console.log(response)
            }
        } catch (error) {
            setSuccessMessage('');
            setErrorMessage('Subscription failed. Please try again.');
            console.log(error)
        }
    };

    return (
        <div>
            <form >
                <div className='input-ic'>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Enter Your Email Here'
                    />
                    <button type='button' onClick={handleSubmit}><svg width="53" height="13" viewBox="0 0 53 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M53 6.5L43 0.726497V12.2735L53 6.5ZM0 7.5H44V5.5H0V7.5Z" fill="white"/>
                </svg></button>
                    {errors.email && <p className='mb-0 ms-2' style={{ color: 'red' }}>{errors.email}</p>}
                </div>
                <div className='my-2 ms-2'>
                    <label htmlFor="tos" className='mb-2'>
                        <input
                        className='custom-input'
                            type="checkbox"
                            id="tos"
                            checked={tosAccepted}
                            onChange={(e) => setTosAccepted(e.target.checked)}
                        />
                        By subscribing, you accepted our <a href="https://docs.stratovm.io/protocol/privacy-policy" target={'_blank'}>policy</a>
                    </label>
                    {errors.tosAccepted && <p className='mb-0' style={{ color: 'red' }}>{errors.tosAccepted}</p>}
                </div>
                {successMessage && <p className='ms-2 mb-0' style={{ color: 'green' }}>{successMessage}</p>}
                {errorMessage && <p className='ms-2 mb-0' style={{ color: 'red' }}>{errorMessage}</p>}
            </form>
        </div>
    );
};

export default NewsLetter;
